.staff-section-container {
	display:flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	width: 1341px;
    margin: 0 auto;
}

@media only screen and (max-width: 1400px) {
	.staff-section-container {
		width: 1100px;
	}
}

@media only screen and (max-width: 1200px) {
	.staff-section-container {
		width: 950px;
	}
}

@media only screen and (max-width: 1000px) {
	.staff-section-container {
		width: 768px;
		justify-content: center;
	}
}

@media only screen and (max-width: 768px) {
    .staff-section-container {
		width: 600px;
	}
}

@media only screen and (max-width: 600px) {
    .staff-section-container {
		width: 420px;
	}
}

@media only screen and (max-width: 480px) {
	.staff-section-container {
		width: 100%;
	}
}
