.footer-container {
	background-color: var(--red-color);
	color: white;
	display: flex;
	justify-content: space-between;
	padding: 75px 10%;
}

.footer-section {
	width: 30%;
	text-align: left;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.social {
	display: flex;
	align-items: flex-end;
}

.footer-section h4 {
	margin-bottom: 20px;
	width: 100%;
}

.follow {
	text-align: right;
}

.footer-section p {
	width: 100%;
}

.footer-section a {
	color: white;
	text-decoration: none;
}

.footer-section a:hover {
	text-decoration: none;
	color: white;
}

.social-links a {
	margin-right: 10px;
}

.copyright {
	background-color: white;
	padding: 20px;
}

.copyright a {
	text-decoration: none;
	font-weight: bold;
	color: black;
}

@media only screen and (max-width: 768px) {
	.footer-container {
		flex-direction: column;
	}

	.footer-section {
		width: 100%;
	}

	.follow {
		text-align: left;
	}

	.social {
		align-items: flex-start;
	}
}
