.App {
	text-align: center;
	font-family: "Uni-Neue-Bold", "Open Sans", Helvetica, Arial, sans-serif;
}

.fade-in {
	animation: fadeIn 1s ease-in-out;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

.icon-logos {
	display: flex;
	width: 50%;
	justify-content: space-evenly;
}

a {
	color: white;
}

.contact-header {
	width: 100%;
	height: 30px;
	color: var(--red-color);
	display: flex;
	justify-content: space-between;
	padding: 0 10%;
}

.contact-header div {
	width: 15%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.contact-header div a {
	color: var(--red-color);
	font-weight: bold;
	margin-left: 1rem;
	font-size: 1vw;
}

@media only screen and (max-width: 768px) {
	.contact-header {
		height: 50px;
	}

	.contact-header div {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.contact-header div a {
		color: var(--red-color);
		font-weight: bold;
		margin-left: 1rem;
		font-size: 3vw;
	}
}

@keyframes fadeIn {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  }
  
