.midPage-container {
	background: var(--red-color);
	color: white;
	height: 200px;
	font-size: 1.6rem;
	display: flex;
	padding: 0px 10%;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.midPage-container a {
	background: white;
	color: var(--red-color);
	font-weight: bold;
}

.midPage-container a:hover {
	background: var(--red-color);
	color: white;
	border: 1px solid white;
}

@media only screen and (max-width: 1500px) {
	/* .midPage-container {
		font-size: 2vw;
	} */
}

@media only screen and (max-width: 1300px) {
	/* .midPage-container {
		font-size: 2.5vw;
	} */
}

@media only screen and (max-width: 768px) {
	/* .midPage-container {
		font-size: 3vw;
	} */
}

@media only screen and (max-width: 450px) {
	.midPage-container {
		font-size: 1.2rem;
	}
}
