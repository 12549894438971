.staff-responsive {
    margin: 0 auto;
    width:1100px;
    text-align: left;
    padding: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.staff-card-container {
    margin: 2em;
}

@media only screen and (min-width: 1400px) {
    .staff-responsive {
        width: 1360px;
    }
}

@media only screen and (max-width: 1200px) {
    .staff-responsive {
        width: 950px;
    }
}

@media only screen and (max-width: 1000px) {
    .staff-responsive {
        width: 768px;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .staff-responsive {
        width: 600px;
    }
}

@media only screen and (max-width: 600px) {
    .staff-responsive {
        width: 420px;
    }
}

@media only screen and (max-width: 480px) {
    .staff-responsive {
        width: 330px;
    }
}

@media only screen and (max-width: 350px) {
    .staff-responsive {
        width: 95%;
    }
}
