/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.card {
  position: relative;
  width: 350px;
  height: 190px;
  background: #333;
  transition: 0.5s;
}

.card:hover {
  height: 450px;
}

.card .lines {
  position: absolute;
  inset: 0;
  background: #000;
  overflow: hidden;
}

.card .lines::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 120px;
  background: linear-gradient(
    transparent,
    #45f3ff,
    #45f3ff,
    #45f3ff,
    transparent
  );
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.card .lines::after {
  content: "";
  position: absolute;
  inset: 2px;
  background: #292929;
}

.card .imgBox {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
  background: #000;
  transition: 0.5s;
  z-index: 10;
  overflow: hidden;
}

.card:hover .imgBox {
  width: 250px;
  height: 250px;
}

.card .imgBox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 150px;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    transparent,
    #ff3c7b,
    #ff3c7b,
    #ff3c7b,
    transparent
  );
  animation: animate2 6s linear infinite;
}

@keyframes animate2 {
  0% {
    transform: translate(-50%, -50%) rotate(360deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.card .imgBox::after {
    content: '';
    position: absolute;
    background: #292929;
    inset: 3px;
}

.card .imgBox img {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    filter: grayscale(1);
}

.card .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
}

.card .content .details {
    padding: 40px;
    text-align: center;
    width: 100%;
    transition: 0.5s;
    transform: translateY(145px);
}

.card:hover .content .details {
    transform: translateY(0px)
}

.card .content .details h2 {
    font-size: 1.25em;
    font-weight: 600;
    color: #45f3ff;
    line-height: 1.2em;
}

.card .content .details h2 span {
    font-size: 0.75em;
    font-weight: 500;
    color: #fff;
}

.card .content .details .data {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}

.card .content .details .data h3 {
    font-size: 1em;
    color: #45f3ff;
    line-height: 1.2em;
    font-weight: 600;
}

.card .content .details .data h3 span {
    font-size: 0.85em;
    font-weight: 400;
    color: #ffff;
}

.card .content .details .actionBtn {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.card .content .details .actionBtn button{
    padding: 10px 30px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 1em;
    font-weight: 500;
    background: #45f3ff;
    color: #222;
    cursor: pointer;
    opacity: 0.8;
}

.card .content .details .actionBtn button:nth-child(2) {
    background: #fff;
}

.card .content .details .actionBtn button:hover {
    opacity: 1;
} */

.card-container {
    width: 325px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    margin: 2em 0;
    /* add mobile breakpoint and drop margins so that card does not shrink */
    border-radius: 4px;
}

.card-container:hover {
    cursor: pointer;
}

.card-top {
    background-color:var(--red-color);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    display: flex;
    justify-content: center;
}

.card-top img {
    border-radius: 50%;
    width:50%;
    margin: 50px 0px;
    border: 4px solid white;
    height: auto;
}

.card-bottom {
    height: 150px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.2rem;
}

.card-bottom .card-details {
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .card-container {
    margin: 2em;
  }
}

@media only screen and (max-width: 500px) {
    .card-container {
        margin: 3em 0.5em;
    }
}

.card-container {
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card-container.flipped {
  transform: rotateY(180deg);
}

.front,
.back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.back {
  position: absolute;
  top: 0;
  padding:1em;
  transform: rotateY(180deg);
  text-align: left;
  overflow: scroll;
}

.bio-p {
  margin-bottom: 10px;
  color: var(--red-color)
}