/* Styles below are for program section of home page */
/* .home-program {
	display: flex;
	width: 100%;
	background: #efefef;
	padding: 100px 5em;
	flex-direction: column;
} */

.home-program {
	display: flex;
	width: 100%;
	/* text-align: left; */
    margin: 0 auto;
    width: 1341px;
	/* background: #efefef; */
}

.program-section {
	display: flex;
	justify-content: space-between;
	width:100%
}

.program-section h3 {
	color: var(--red-color);
	text-align: center;
	font-size:1.6rem;
}

.program-image img {
	height: auto;
	width: 100%;
	margin-bottom: 20px;
}

.MuiButton-outlinedPrimary {
	color: var(--red-color)
}

@media only screen and (max-width: 1500px) {
	/* .program-section-button .button {
		font-size: 1.2vw;
	} */
}

@media only screen and (max-width: 1400px) {
	.home-program {
		width: 1100px;
	}
}

@media only screen and (max-width: 1300px) {
	/* .program-section-button .button {
		font-size: 1.5vw;
	} */
}

@media only screen and (max-width: 1200px) {
	.home-program {
		width: 950px;
	}
}

@media only screen and (max-width: 1000px) {
	.home-program {
		width: 768px;
	}
}

@media only screen and (max-width: 768px) {
	.home-program {
		width: 600px;
	}

	.program-section {
		flex-direction: column;
		align-items: center;
	}

	.program-image:first-of-type {
		margin: 0 0 20px 0;
	}

	.program-image:last-of-type {
		margin: 20px 0 0 0;
	}

	.program-image {
		width: 100% !important;
		margin: 20px 0;
	}

	/* .program-section-button .button {
		font-size: 2.5vw;
	} */
}

@media only screen and (max-width: 600px) {
    .home-program {
		width: 420px;
	}
}

@media only screen and (max-width: 480px) {
	.home-program {
		width: 100%;
	}
}

@media only screen and (max-width: 450px) {
	/* .program-section-button .button {
		font-size: 4vw;
	} */
}

/* Styles above are for program section of home page */
