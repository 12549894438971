/* CSS For the Join Our Team section */

.employment {
    display: flex;
    margin: 20px 0;
    padding-top: 3.3vmax;
    padding-bottom: 3.3vmax;
    padding-right: 4vw;
    padding-left: 4vw;
    gap: 2rem;
  }
  
  .employment-text {
    flex: 1;
    /* min-width: 300px; */
    text-align: left;
  }

  .employment-text h2 {
    font-size: 3rem;
    color: rgb(160, 36, 36);
    margin-bottom: 32px;
  }

  .employment-text p {
    font-size: 1.125rem; /* Increase font size for better readability */
    line-height: 1.6; /* Increase line height for better spacing */
    margin-bottom: 20px; /* Add some space between paragraphs */
    color: #333; /* Use a color that ensures good contrast */
  }
  
  .employment-image {
    width: 50%;
  }
  
  .employment-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  @media (max-width: 820px) {
    .employment-text h2 {
        font-size: 2rem;
    }
   }
  
  @media (max-width: 768px) {
    .employment {
      flex-direction: column;
    }
  
    .employment-text {
        text-align: left;
    }
  
    .employment-image {
      margin-top: 20px;
      width: 100%;
    }
  }

/* CSS For Our Values Section */

.our-values {
    width: 60%;
    margin: 20px auto; /* Center the section horizontally */
    padding-top: 4vmax;
    padding-bottom: 4vmax;
  }
  
  .our-values h2 {
    margin-bottom: 6rem; /* Space between title and the first value */
    text-align: center;
    font-size: 3rem;
    color: rgb(160, 36, 36);
    font-weight: bold;
  }
  
  .our-values ul {
    padding: 0;
  }
  
  .value-item {
    display: flex;
    flex-direction: row; /* Ensure title and description are side by side */
    align-items: flex-start; /* Align items to the start */
    margin-bottom: 3rem;
  }
  
  .value-title {
    font-weight: bold;
    flex: 0 0 20%; /* Make sure the title has a fixed width and can wrap */
    margin-right: 20px; /* Space between title and description */
    text-align: left;
    word-wrap: break-word; /* Ensure long words break to the next line */
  }
  
  .value-description {
    flex: 1;
    text-align: left; /* Align text to the left */
  }
  
  @media (max-width: 820px) {
    .our-values {
        width: 90%;
    }

    .our-values h2 {
      font-size: 2rem;
      margin-bottom: 4rem;
    }
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .value-item {
      flex-direction: column;
      align-items: flex-start; /* Align items to the start */
      text-align: left;
    }
  
    .value-title {
      margin-right: 0;
      margin-bottom: 5px; /* Space between title and description */
      width: 100%; /* Ensure the title takes full width in small screens */
    }
  
    .value-description {
      text-align: left; /* Ensure description text is left-aligned */
    }
  }
  