.job-application-page {
    margin: auto;
    font-family: Arial, sans-serif;
  }

  .job-details {
    padding: 4rem 2rem;
  }
  
  .job-details h1 {
    color: #8b0000;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    text-align: left;
  }
  
  .job-details p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .job-details ul, .job-details ol {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .job-details ul li, .job-details ol li {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .job-details h2 {
    color: #8b0000;
    font-size: 1.75rem;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  .button-container {
    text-align: left;
    margin-top: 3rem;
  }

  .button-container a {
    color: white;
    background-color: rgb(160, 36, 36);;
  }

  .button-container a:hover {
    background-color: white;
    color: rgb(160, 36, 36);;
  }
  
  .application-form {
    margin-top: 40px;
    text-align: left;
  }
  
  .application-form h2 {
    color: #8b0000;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: left;
  }

  .teQAzf {
    width: 100vw;
  }
  
  iframe {
    width: 100%;
  }

  .note {
    padding: 1rem;
  }
  
  @media (max-width: 768px) {
    .job-details {
        padding: 2rem;
    }
  
    .job-details h1 {
      font-size: 2rem;
    }
  
    .job-details h2 {
      font-size: 1.5rem;
    }

    .note {
        padding: 2rem;
      }

    .button-container {
        text-align: center;
        margin-top: 3rem;
    }
  
    .application-form h2 {
      font-size: 1.75rem;
    }
  }
  