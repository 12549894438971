/* css for home about section */

.home-about {
	display: flex;
	width: 100%;
	text-align: left;
    margin: 0 auto;
    width: 1341px;
	text-align: left;
}

.about-section {
	width: 50%;
	padding: 2% 2% 2% 0;
	display: flex;
	flex-direction: column;
}

.about-section h2 {
	color: #a02424 ;
	margin-bottom: .5rem;
}

.about-img-section {
	width: 50%;
	padding: 2% 0 2% 2%;
	/* font-size: 5rem; */
}

.about-img-section img {
	height: auto;
}

.about-section button {
	height: 50px;
}

.about-section p {
	font-size: 1.3rem;
}

.mobile-about {
	display: none;
}

.home-about-image {
	width:100%;
}

@media only screen and (max-width: 1500px) {
	/* .about-section button {
		height: 50px;
		font-size: 1.6rem;
	} */
}

@media only screen and (max-width: 1400px) {
	.home-about {
		width: 1100px;
	}
}

@media only screen and (max-width: 1300px) {
	/* .about-section button {
		height: 50px;
		font-size: 1.5vw;
	} */
}

@media only screen and (max-width: 1200px) {
	.home-about {
		width: 950px;
	}
}

@media only screen and (max-width: 1000px) {
	.home-about {
		width: 768px;
	}
}

@media only screen and (max-width: 768px) {
	.home-about {
		flex-direction: column;
		width: 600px;
	}

	.about-section {
		width: 100%;
		padding: 0;
		align-items: center;
	}

	.about-img-section {
		width: 100%;
		padding: 0;
		margin-top: 20px;
	}

	.desktop-about {
		display: none;
	}

	.mobile-about {
		display: block;
	}

	/* .about-section button {
		height: 50px;
		font-size: 2.5vw;
	} */
}

@media only screen and (max-width: 600px) {
    .home-about {
		width: 420px;
	}
}

@media only screen and (max-width: 480px) {
	.about-section-parent {
		margin: 0 1rem;
	}
	.home-about {
		width: 100%
	}

	/* .about-section button {
		height: 50px;
		font-size: 4vw;
	} */
}

@media only screen and (max-width: 450px) {
	/* .about-section button {
		height: 50px;
		font-size: 4vw;
	} */
}
