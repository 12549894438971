.banner-title-container {
	position: relative;
	text-align: center;
	color: white;
  }
  
  /* Bottom left text */
  .program-centered-title {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2.2rem;
  }

  .banner-title-container img {
	width: 100%;
	max-width:1920px;
	min-height: 125px;
  }

  @media only screen and (max-width: 820px) {
	.program-centered-title {
		font-size: 1.2rem;
	}
}