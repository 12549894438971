.about-section-parent {
    padding-top: 60px;
    padding-bottom: 60px;
}

.program-section-parent {
    background-color: #efefef;
    padding: 60px 0;
}

.midpage-section-parent {
    padding-top: 60px;
    padding-bottom: 60px;
    background: var(--red-color);
}

.staff-section-parent {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media only screen and (max-width: 480px) {
	.program-section-parent {
		padding: 60px 1rem;
	}
}