.program-detail-card-parent {
    display: flex;
    justify-content: space-between
}

.program-detail-card-container {
    width: 33%;
    padding: 0 15px 0 0;
}

.program-detail-card-container h2 {
    color: rgb(160, 36, 36)
}

.custom-table-parent {
    width:66%;
}

.custom-table-parent h3 {
    text-align: center;
}

@media only screen and (max-width: 820px) {
	.program-detail-card-parent {
        flex-direction: column;
    }

    .program-detail-card-container {
        width: 100%;
        padding: 0 0 40px 0;
    }

    .custom-table-parent {
        width: 100%;
    }
}