.program-responsive {
    margin: 0 auto;
    width:1100px;
    text-align: left;
    padding: 40px 0;
}

.program-description-header {
    color: rgb(160, 36, 36)
}

@media only screen and (min-width: 1400px) {
    .program-responsive {
        width: 1360px;
    }
}

@media only screen and (max-width: 1200px) {
    .program-responsive {
        width: 950px;
    }
}

@media only screen and (max-width: 1000px) {
    .program-responsive {
        width: 768px;
    }
}

@media only screen and (max-width: 768px) {
    .program-responsive {
        width: 600px;
    }
}

@media only screen and (max-width: 600px) {
    .program-responsive {
        width: 420px;
    }
}

@media only screen and (max-width: 480px) {
    .program-responsive {
        width: 330px;
    }
}

@media only screen and (max-width: 350px) {
    .program-responsive {
        width: 95%;
    }
}
