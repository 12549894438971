.nav-bar {
    background-color: var(--red-color);
    color: white;
}

.logo-container {
	width: 5%;
    margin: 10px 0 10px 0;
}

.logo-container .logo {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 992px) {
    .logo-container {
        width: 8%;
    }

    .logo-container .logo {
        width: 100%;
        height: auto;
    }
}